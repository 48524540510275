import React from "react";
import "./style.css";

export default function List({ data }) {
  return (
    <div className="max-w-[85%] mx-auto px-[10px]">
      <p className="text-[20px] leading-9 text-[#323a44] text-center font-semibold mb-[2.5rem] show">
        {data?.heading}
      </p>
      <div className="flex flex-wrap max-w-[952px] w-full">
        {data?.list?.map((item, index) => {
          return (
            <div
              className="flex w-full flex-col sm:flex-row items-center justify-between mb-5 show"
              key={index}
            >
              <div className="flex w-full justify-center ps-svg">
                <div dangerouslySetInnerHTML={{ __html: item.svg }} />
              </div>

              <p className="text-[20px] text-center md:text-left w-full text-[#323a44] font-bold leading-[32px]">
                {item.content}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
